<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				newsdic {{perf}}
				<DataTable :value="newsdics" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
					:globalFilterFields="['dicname', 'wordlist']"
          selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="dicname" header="辞書名" :sortable="true"></Column>
					<Column field="wordlist" header="単語リスト" :sortable="true"></Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import NewsdicService from '@/service/NewsdicService';

export default {
	data() {
		return {
			newsdics: null,
			filters1: {},
			perf: null,
		}
	},
	newsdicService: null,
	created() {
		this.newsdicService = new NewsdicService();
		this.initFilters1();
	},
	mounted() {
		this.newsdicService.getAll()
			.then(d => {
				this.newsdics = d.data;
				this.perf = d.perf;
			}
		);		
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		}
	}
}
</script>

<style scoped>

</style>
